import React from "react";
import { Link } from "react-router-dom";

import muddaLogofooter from "../../assets/images/muddaLogoHeader.svg";
import googlePlay from "./../../assets/images/icon_GooglePlay.png";
import appStore from "./../../assets/images/icon_AppStore.png";

import "../landingPage/landingPage.Sass";
import "aos/dist/aos.css";
import style from "../landingPage/landingPage.module.css";

export default function footer() {
  return (
    <div className={style.footer}>
      <div className={style.mainConatiner}>
        <hr style={{ height: "1px", background: "#b2b2b2" }} />
        <div className={style.terms}>
          <div className={style.frow}>
            <img src={muddaLogofooter} width="180px" alt="logo" />
            <div
              className={style.lp4button}
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <div className=" mb-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.mudda.mudda"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={googlePlay} alt="gp" className={style.playStore} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mudda.mudda"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appStore} alt="gp" className={style.playStore} />
                </a>
              </div>
            </div>
          </div>

          <div className={style.frow}>
            <div className="footeremail">
              <i class="fas fa-envelope"></i> <span>contact@mudda.in</span>
            </div>
            <div>
              <div style={{ fontSize: "14px", margin: "0px 8px" }}>
                Follow us
              </div>
              <div className="footer-icons">
                <ul style={{ padding: "0px", marginBottom: "0px" }}>
                  <li>
                    <a
                      href="https://twitter.com/mudda_india"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/MuddaApp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/mudda_india/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/mudda/?viewAsMember=true"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="fab fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ height: "1px", background: "#b2b2b2" }} />
        <div className={style.terms2}>
          <div className={style.footerPolicy}>
            <Link to="/privacy-policy">
              <span className="footerPolicy">Privacy Policy</span>
            </Link>
            <Link to="">
              <span>Terms and condition</span>
            </Link>
          </div>

          <div className={style.footercopyright}>
            <p style={{ margin: 0, fontSize: "14px", textAlign: "center" }}>
              © Aitra Development Pvt. Ltd. 2021, All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
