import React from "react";

import { Row, Col } from "react-bootstrap";
import { S2Sing, P2fe } from "./styledComponent";

import s6 from "./../../assets/images/s6.svg";
import "./landingPage.Sass";

import "aos/dist/aos.css";

export default function missionSection() {
  return (
    <div>
      <contaniner fluid>
        <S2Sing style={{ maxWidth: "1140px", margin: "auto" }}>
          <Row>
            <Col
              lg="6"
              md="6"
              sm="12"
              className="Cols2textDiv"
              xs={{ order: "last" }}
            >
              <div className="s2textDiv2" data-aos="zoom-in-left">
                <P2fe>
                  A <span className="p2span">mission</span> to reach every
                  citizen of India
                </P2fe>

                <ul className="s2divul" style={{ paddingLeft: "2px" }}>
                  <li>Covering over 10 Lakh groups</li>
                  <li>Help every citizen to raise their voice</li>
                  <li>Level Wise Post rights</li>
                </ul>
              </div>
            </Col>

            <Col md="6" className="Cols2textDiv s5dance">
              <img data-aos="zoom-in-right" src={s6} alt="o" className="img" />
            </Col>
          </Row>
        </S2Sing>
      </contaniner>
    </div>
  );
}
