import React, { useState, useEffect } from "react";

import TextTransition, { presets } from "react-text-transition";

import style from "./landingPage.module.css";

import hero from "./../../assets/images/hero.png";

import "./landingPage.Sass";

import "aos/dist/aos.css";

import googlePlay from "./../../assets/images/icon_GooglePlay.png";
import appStore from "./../../assets/images/icon_AppStore.png";

const TEXTS = ["State", "District", "Block", "Panchayat", "Village"];

const HeroSection = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <div className="container-fluid">
      <div className={style.mainConatiner}>
        <div className={`${style.heroSection} row`}>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className={`${style.introdiv} flexColumn`}>
              <div className={style.introline}>
                Raise your social issues in your
              </div>

              <div className={`${style.introline} ${style.typeText} `}>
                <div style={{ color: "#FD8061" }}>
                  <TextTransition
                    text={TEXTS[index % TEXTS.length]}
                    springConfig={presets.slow}
                    inline={true}
                    noOverflow={true}
                    delay={1000}
                  />
                </div>

                <div style={{ marginLeft: "16px", marginRight: "16px" }}>
                  Community
                </div>
              </div>
              <div className={style.lp4button}>
                <div className=" mb-2">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mudda.mudda"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlay}
                      alt="gp"
                      className={style.playStore}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mudda.mudda"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appStore} alt="gp" className={style.playStore} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 pd-0">
            <div className={style.heroImage}>
              <img src={hero} alt="img" width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
