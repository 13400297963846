import { BrowserRouter as Router,HashRouter, Route } from "react-router-dom";
import LandingPage from "./app/landingPage/landingPage";
import PrivacyPolicy from "./app/PrivacyPolicy/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <HashRouter history={History}>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
      </HashRouter>
    </div>
  );
}

export default App;
