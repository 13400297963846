import React from "react";

import style from "./landingPage.module.css";
import { Row, Col } from "react-bootstrap";
import { P1, P3, S3div } from "./styledComponent";

import s9 from "./../../assets/images/s9.png";
import s9m from "./../../assets/images/s9m.png";
import "./landingPage.Sass";

import "aos/dist/aos.css";

import googlePlay from "./../../assets/images/icon_GooglePlay.png";
import appStore from "./../../assets/images/icon_AppStore.png";

export default function callToAction() {
  return (
    <div className={style.jtcParentDiv} data-aos="fade-up">
      <S3div>
        <Row>
          <Col
            lg="6"
            md="6"
            sm="12"
            className="Cols3textDiv"
            xs={{ order: "first" }}
          >
            <div className="s3textDiv2">
              <P3>Download the app </P3>

              <P1>
                Get the mudda app on google play store and connect with the
                community accross inda to disscuss the latest MUDDA
              </P1>
              <div className={style.lp4button}>
                <div className=" mb-2">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mudda.mudda"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={googlePlay}
                      alt="gp"
                      className={style.playStore}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.mudda.mudda"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appStore} alt="gp" className={style.playStore} />
                  </a>
                </div>
              </div>
            </div>
          </Col>

          <Col md="6" className="Cols3textDiv" style={{ overflow: "hidden" }}>
            <img
              src={s9}
              className="img d-none d-lg-block"
              width={"490px"}
              alt="group details"
            />
            <img
              src={s9m}
              className="d-lg-none"
              style={{ width: "320px" }}
              alt="group details"
            />
          </Col>
        </Row>
      </S3div>
    </div>
  );
}
