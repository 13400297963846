import React, { useEffect } from "react";
import { Parallax } from "react-parallax";

import "./landingPage.Sass";

import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "./heroSection";
import Features from "./features";
import GroupsInfo from "./groupsInfo";
import RaiseVoiceBanner from "./raiseVoiceBanner";
import EverythingSection from "./everythingSection";
import MissionSection from "./missionSection";
import CallToAction from "./callToAction";
import Footer from "../components/footer";
import Header from "../components/header";

import heroBg from "../../assets/images/heroBg.svg";

const LandingPage = () => {
  useEffect(() => {
    AOS.init({
      durations: 3000,
      animatedClassName: "aos-animate",
      useClassNames: false,
    });
  }, []);
  return (
    <React.Fragment>
      <Parallax
        strength={300}
        className="lpSection2"
        bgImage={heroBg}
        bgStyle={{ backgroundSize: "cover" }}
      >
        {/* <section className="lpSection2"> */}
        <Header />
        <HeroSection />
        {/* </section> */}
      </Parallax>
      {/* <section className="lpSection2">
        <Header />
        <HeroSection />
      </section> */}

      <Features />
      <GroupsInfo />
      <RaiseVoiceBanner />
      <EverythingSection />
      <MissionSection />
      <CallToAction />
      <Footer />
    </React.Fragment>
  );
};

export default LandingPage;
