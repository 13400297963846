import React from "react";

import style from "./landingPage.module.css";
import { Row, Col } from "react-bootstrap";
import { S2Mission } from "./styledComponent";

import s4 from "./../../assets/images/s4.svg";
import "./landingPage.Sass";

import "aos/dist/aos.css";

export default function raiseVoiceBanner() {
  return (
    <div
      className={style.jtcP8arentDiv}
      style={{ marginTop: "80px" }}
      data-aos="fade-up"
    >
      <S2Mission style={{ width: "100%" }}>
        <Row style={{ width: "100%" }}>
          <Col
            md="8"
            sm="12"
            className="Cols2textDiv"
            lg={{ order: "last" }}
            xs={{ order: "first" }}
          >
            <div className={style.s2textDiv2}>
              <div className={style.raiseTitle}>
                Raise your <span className={style.p2span}>voice</span>
              </div>
              <div className={style.raiseText}>
                <ul>
                  <li>Facing any social issue?</li>
                  <li>
                    Need a platform to reach out the respective authority in
                    your locality?
                  </li>
                </ul>
                <div className={style.call}>
                  Join <span>Mudda App</span> to raise your voice and discuss
                  about the latest Mudda.
                </div>
              </div>
              <div className={style.lp8button}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mudda.mudda"
                  target="_blank"
                  rel="noreferrer"
                >
                  Download the app
                </a>
              </div>
            </div>
          </Col>
          <Col md="4" className="Cols8textDiv s5img">
            <img src={s4} alt="o" />
          </Col>
        </Row>
      </S2Mission>
    </div>
  );
}
