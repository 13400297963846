import React from "react";

import "../landingPage/landingPage.Sass";
import "aos/dist/aos.css";
import style from "../landingPage/landingPage.module.css";

import { Link } from "react-router-dom";

import muddaLogoHeader from "./../../assets/images/headerlogo.svg";

export default function header() {
  return (
    <div className={style.mainConatiner}>
      <nav
        class="navbar navbar-expand-md navbar-dark "
        style={{ padding: "2rem 0", maxWidth: "1280px", margin: "auto" }}
      >
        <div className="container-fluid ">
          <div className={style.navbarbrand}>
            <a className="navbar-brand" href="https://mudda.in">
              <img
                src={muddaLogoHeader}
                className={style.headerLogo}
                alt="logo"
              />
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ border: "none", outline: "none" }}
          >
            <span className="navbar-toggler-icon"> </span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ">
              <div className={style.navButtondiv}>
                <li class="nav-item">
                  <Link class="nav-link" aria-current="page" to="#">
                    <button className={style.loginbutton}>Login</button>
                  </Link>
                </li>
                <li class="nav-item ml-3 ml-lg-1">
                  <Link class="nav-link" href="#">
                    <button className={style.signupbutton}>Signup</button>
                  </Link>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
