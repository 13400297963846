import React from "react";

import style from "./landingPage.module.css";

import s1a from "./../../assets/images/s1a.svg";
import s1b from "./../../assets/images/s1b.svg";
import s1c from "./../../assets/images/s1c.svg";
import s1d from "./../../assets/images/s1d.svg";
import s1e from "./../../assets/images/s1e.svg";
import s1f from "./../../assets/images/s1f.svg";
import "./landingPage.Sass";

import "aos/dist/aos.css";

const featureList = [
  {
    id: 1,
    name: "Timeline (Own Community)",
    description:
      "General info about this feature can be added here but it should be short",
    icon: s1a,
  },
  {
    id: 2,
    name: "Location-Named Groups",
    description:
      "General info about this feature can be added here but it should be short",
    icon: s1b,
  },
  {
    id: 3,
    name: "Social Issues & Problems",
    description:
      "General info about this feature can be added here but it should be short",
    icon: s1c,
  },
  {
    id: 4,
    name: "Location Based Social Facts",
    description:
      "General info about this feature can be added here but it should be short",
    icon: s1d,
  },
  {
    id: 5,
    name: "Government Schemes & Works",
    description:
      "General info about this feature can be added here but it should be short",
    icon: s1e,
  },
  {
    id: 6,
    name: "Panchayati - Live Discussion",
    description:
      "General info about this feature can be added here but it should be short",
    icon: s1f,
  },
];

export default function features() {
  return (
    <div className="container-fluid">
      <div className={style.mainConatiner}>
        <div className={style.featureParentDiv}>
          <div className={style.maintitle}>Features</div>

          <div
            class={style.featureContainer}
            // style={{
            //   display: "flex",
            //   flexDirection: "row",
            //   alignItems: "center",
            //   justifyContent: "center",
            // }}
          >
            {featureList.map((item) => {
              return (
                <div class={style.wrapper}>
                  <div
                    className={`${style.featureDiv} col-sm`}
                    data-aos="fade-up"
                    key={item.id}
                  >
                    <div className={style.featureDivimg}>
                      <img
                        src={item.icon}
                        alt={item.name}
                        className={`${style.ficon} `}
                      />
                    </div>

                    <div>
                      <h6>{item.name}</h6>
                      {/* <h5>{item.description}</h5> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
