import React from "react";

import { Row, Col } from "react-bootstrap";
import { S2div, P2fe } from "./styledComponent";

import s5 from "./../../assets/images/s5.svg";
import "./landingPage.Sass";

import "aos/dist/aos.css";

export default function everythingSection() {
  return (
    <div>
      <contaniner fluid>
        <S2div
          style={{ maxWidth: "1140px", margin: "auto", paddingBottom: "0px" }}
        >
          <Row>
            <Col
              md="6"
              sm="12"
              className="Cols2textDiv"
              xs={{ order: "last" }}
              lg={{ order: "first" }}
            >
              <div className="s2textDiv2" data-aos="zoom-in-right">
                <P2fe>
                  Everything you need to <span className="p2span">know</span>{" "}
                </P2fe>

                <ul className="s2divul" style={{ paddingLeft: "2px" }}>
                  <li>Social Issues </li>
                  <li>Live Audio Discussion</li>
                  <li>Social Point System</li>
                  <li>Post Rights Based On Your Social Status</li>
                </ul>
              </div>
            </Col>

            <Col md="6" className="Cols2textDiv s5dance">
              <img data-aos="zoom-in-left" src={s5} alt="o" className="img" />
            </Col>
          </Row>
        </S2div>
      </contaniner>
    </div>
  );
}
