import React from "react";

import { Row, Col } from "react-bootstrap";
import { S2Info, P2 } from "./styledComponent";

import s2 from "./../../assets/images/s2.svg";
import s2mob from "./../../assets/images/s2mob.png";
import "./landingPage.Sass";
import style from "./landingPage.module.css";
import "aos/dist/aos.css";

export default function groupsInfo() {
  return (
    <div>
      <div className={style.mainConatiner} data-aos="fade-up">
        <contaniner fluid>
          <S2Info>
            <Row style={{ maxWidth: "1280px", paddingTop: "10px" }}>
              <Col
                lg="6"
                md="6"
                sm="12"
                className="Cols2textDiv"
                xs={{ order: "first" }}
              >
                <div className="s2textDiv2" style={{ textAlign: "center" }}>
                  <P2>
                    Covering every <span className="p2span">corner </span> of
                    the India
                  </P2>
                </div>
              </Col>

              <Col md="6" className="Cols2textDiv">
                <img
                  src={s2}
                  style={{ width: "100%" }}
                  class="d-none d-lg-block"
                  alt="group details"
                />
                <img
                  src={s2mob}
                  class="d-lg-none"
                  style={{ width: "280px" }}
                  alt="group details"
                />
              </Col>
            </Row>
          </S2Info>
        </contaniner>
      </div>
    </div>
  );
}
